import { FaComments } from "react-icons/fa6";
import { BiSolidUpvote } from "react-icons/bi";
import { useState, useEffect } from 'react'

import { RiEmotionNormalLine, RiEmotionUnhappyLine, RiEmotionHappyLine } from "react-icons/ri"; 



const getSentimentInfo=(input)=>{
  let icon, bgColor, textColor;
  
  switch (input) {
    case 'POSITIVE':
      icon = <RiEmotionHappyLine className="mr-1 align-middle" />;
      bgColor = 'bg-green-200'; // Adjust the color as needed
      textColor = 'text-green-800'; // Adjust the text color as needed
      break;
    case 'NEGATIVE':
      icon = <RiEmotionUnhappyLine className="mr-1 align-middle" />;
      bgColor = 'bg-red-200'; // Adjust the color as needed
      textColor = 'text-red-800'; // Adjust the text color as needed
      break;
    default:
      icon = <RiEmotionNormalLine className="mr-1 align-middle" />;
      bgColor = 'bg-gray-200'; // Adjust the color as needed
      textColor = 'text-gray-800'; // Adjust the text color as needed
  }

  return {icon, bgColor,  textColor}
}

const getSubredditColor = (subreddit) => {
  const colors = {
    investing: 'bg-emerald-300',
    stocks: 'bg-blue-300'
  };
  return colors[subreddit.trim()] || 'bg-amber-300'; // Default color
};
const PostDetail = ({ post }) => {
  const [showDialog, setShowDialog] = useState(false);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const sentimentInfo = getSentimentInfo(post.comment_sentiment);
  return (
    <li className="px-3 py-3 flex flex-col sm:flex-row items-start">
      <div className="w-full">
        <div className="flex justify-between items-center w-full">
          <a href={post.permalink} target="_blank" rel="noopener noreferrer" className="font-medium text-xl md:text-2xl text-blue-500 break-words overflow-hidden">
            {post.title}
          </a>
          <span className="ml-4 text-right whitespace-nowrap">{post.created}</span>
        </div>
        <div className="flex items-center mt-1 mb-1 space-x-1">
          <span className={`rounded-full ${getSubredditColor(post.subreddit)} px-2 py-1 uppercase text-xs font-bold`}>
            {post.subreddit}
          </span>

          <span className="flex items-center rounded-full px-2 py-1 bg-sky-300 uppercase text-xs font-bold cursor-pointer" onClick={toggleDialog}>
        <FaComments className="mr-1 align-middle" /><span>{post.num_comments}</span>
      </span>

      {showDialog && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={() => setShowDialog(false)}>
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
            <div className="mx-auto flex items-center justify-center ">           
              </div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Summarized Comments</h3>
              <div className="mt-2 px-7 py-3">
              <div className="flex justify-center">
  <p className="max-w-prose text-justify text-base text-gray-700 leading-relaxed my-4">
                  {post.summarized_comments}</p>
                </div>
              </div>
              <div className="items-center px-4 py-3">
                <button onClick={() => setShowDialog(false)} className="px-4 py-2 bg-gray-800 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}






          <span className="flex items-center rounded-full px-2 py-1 bg-sky-300 uppercase text-xs font-bold">
            <BiSolidUpvote className="mr-1 align-middle" /><span>{post.ups}</span>
          </span>
          <span className={`flex items-center rounded-full px-2 py-1 ${sentimentInfo.bgColor} uppercase text-xs font-bold ${sentimentInfo.textColor}`}>
            {sentimentInfo.icon}<span>{post.comment_sentiment} {(post.comment_sentiment_confidence * 100).toFixed(1)}%</span>
        </span>         
        </div>
        <div className="flex items-center mt-1 mb-1 space-x-1">
        <p className="line-clamp-5 text-base sm:text-lg overflow-hidden text-gray-700 font-normal">
          {post.summarized_selftext}
        </p>
        </div>
      </div>
    </li>
  );
};

export default PostDetail;