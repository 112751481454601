import { useState, useEffect, useCallback } from 'react'
import { BiCalendar } from "react-icons/bi"
import Search from "./components/Search"
import PostDetail from "./components/PostDetail"

function decodeHtmlEntities(text) {
  if (!text) return ''; // Return empty if text is falsy
  return text.replace(/&amp;/gi, '&')
             .replace(/&lt;/gi, '<')
             .replace(/&gt;/gi, '>')
             .replace(/&quot;/gi, '"')
             .replace(/&#39;/gi, "'");
}


function App() {

  let [postList, setPostList] = useState([]);
  let [timestamp, setTimeStamp] = useState([]);
  let [query, setQuery] = useState("");
  let [sortBy, setSortBy] = useState("ups");
  let [orderBy, setOrderBy] = useState("desc");

  const filteredPosts = postList.filter(item => {
    const queryLower = query.toLowerCase();
    // Decode HTML entities before comparing
    const title = decodeHtmlEntities(item.title?.toLowerCase() || "");
    const selftext = decodeHtmlEntities(item.selftext?.toLowerCase() || "");
    // Extend the search to include title and selftext fields
    return (
      item.subreddit?.toLowerCase().includes(queryLower) ||
      title.includes(queryLower) ||
      selftext.includes(queryLower)
    );
  }).sort((a, b) => {
    let order = (orderBy === 'asc') ? 1 : -1;
    if (sortBy === 'subreddit') {
      // String comparison for subreddit
      return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase()) * order;
    } else {
      // Numeric comparison for ups and score
      return (a[sortBy] - b[sortBy]) * order;
    }
  });
  
  
  const fetchData = useCallback(() => {
    fetch('/api/reddit')
      .then(response => response.json())
      .then(data => {
        setPostList(data)
        setTimeStamp(data[0].upload_date)
      });
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  return (

    
    <div className="App container mx-auto px-2 sm:px-4 md:px-6 lg:px-8 font-thin items-center">
      <h1 className="flex items-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold dark:text-gray-900 pb-4 pt-4">Reddit Feed Aggregator
      <span className="bg-blue-100 text-blue-800 text-sm sm:text-base md:text-lg lg:text-xl font-semibold me-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ms-2">Personal Use</span></h1>

      <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
        <div className="flex">
          <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
          <div>
            <p className="font-bold text-base ">The feed is based on {timestamp} data.</p>
            <ul class="list-disc">
            <li><p className="text-sm font-light">Subreddit: <span className='font-bold' >Investing</span>, <span className='font-bold' >Stocks</span> and <span className='font-bold' >Wall Street Bets</span></p></li>
            <li><p className="text-sm font-light">Posts are summarized by <span className='font-bold' >Hugging Face models</span></p></li>
            <li><p className="text-sm font-light">The app uses React and Python, and is hosted on AWS.</p></li>
            </ul>
          </div>
        </div>
      </div>
     
      <Search query={query}
        onQueryChange={myQuery => setQuery(myQuery)}
        orderBy={orderBy}
        onOrderByChange={mySort => setOrderBy(mySort)}
        sortBy={sortBy}
        onSortByChange={mySort => setSortBy(mySort)}
      />

      <ul className="divide-y divide-gray-200">
        {filteredPosts
          .map(post => (
            <PostDetail key={post.id}
              post={{...post, title: decodeHtmlEntities(post.title), selftext: decodeHtmlEntities(post.selftext)}}
              

            />
          ))
        }
      </ul>
    </div>
  );
}

export default App;