import { BiSearch, BiCaretDown, BiCheck } from "react-icons/bi"
import { useState } from 'react';

const DropDown = ({ toggle, sortBy, onSortByChange, orderBy, onOrderByChange }) => {
  if (!toggle) {
    return null;
  }
  return (
    <div className="origin-top-right absolute right-0 mt-2 w-56
      rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
  
        <div onClick={() => onSortByChange('ups')}
          className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex justify-between cursor-pointer"
          role="menuitem">Up Vote {(sortBy === 'ups') && <BiCheck />}</div>
                     
        <div onClick={() => onSortByChange('subreddit')}
          className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex justify-between cursor-pointer"
          role="menuitem">Subreddit {(sortBy === 'subreddit') && <BiCheck />}</div>

        <div onClick={() => onOrderByChange('asc')}
          className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex justify-between cursor-pointer border-gray-1 border-t-2"
          role="menuitem">Asc {(orderBy === 'asc') && <BiCheck />}</div>
        <div onClick={() => onOrderByChange('desc')}
          className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex justify-between cursor-pointer"
          role="menuitem">Desc {(orderBy === 'desc') && <BiCheck />}</div>
      </div>
    </div>
  )
}
const Search = ({ query, onQueryChange, sortBy, onSortByChange, orderBy, onOrderByChange }) => {
  let [toggleSort, setToggleSort] = useState(false);
  return (
    <div className="py-5">
      <div className="mt-1 flex rounded-md shadow-sm w-full">

        <input
          type="text"
          name="query"
          id="query"
          value={query}
          onChange={(event) => { onQueryChange(event.target.value) }}
          className="focus:ring-indigo-500 focus:border-indigo-500 border-2 flex-grow pl-10 pr-10sm:text-sm border-gray-300 rounded-sm"
          placeholder="Search"
        />
        <button
          type="button"
          onClick={() => { setToggleSort(!toggleSort) }}
          className="px-4 py-2 bg-blue-500 text-sm text-white hover:bg-blue-600 rounded-r-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Sort By <BiCaretDown className="ml-2" />
        </button>
      </div>    



      {toggleSort && (
        <DropDown
          toggle={toggleSort}
          sortBy={sortBy}
          onSortByChange={onSortByChange}
          orderBy={orderBy}
          onOrderByChange={onOrderByChange}
        />
      )}
    </div>
  );
};

export default Search;